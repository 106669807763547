import $ from 'jquery'

export function languageSwitcher() {
  if ($('.language-switcher').length > 0) {
    if (!$('.language-switcher').hasClass('-processed')) {
      $('.language-switcher').addClass('-processed');

      let $switcher = $('.language-switcher');
      let $trigger = $switcher.find('.language-switcher__trigger');
      let $items = $switcher.find('.language-switcher__popover').find('.language-switcher__item');

      $trigger.on('click.pxtrigger', function() {
        $switcher.toggleClass('-open');
      });

      $items.each(function() {
        let $e = $(this);

        $e.on('click.pxtrigger', function() {
          $items.removeClass('-selected');
          $e.addClass('-selected');

          $trigger.html($e.clone());

          setTimeout(() => {
            $switcher.removeClass('-open');
          }, 500);
        });
      });

      $(window).on('click.pxtrigger', function(event) {
        if ($(event.target).closest('.language-switcher').length === 0)
          $switcher.removeClass('-open');
      });

      $(window).on('keyup.pxtrigger', function(event) {
        if (event.key === 'Escape')
          $switcher.removeClass('-open');
      });
    }
  }
}
