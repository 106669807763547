import $ from 'jquery';

export function HomePage() {
  if (window.intervalIds) {
    window.intervalIds.forEach(clearInterval);
  }
  window.intervalIds = [];

  let isMobile = $(window).width() < 1280;
  let timeOut = !isMobile ? 1500 : 0;

  // Hero animation
  $('.main-page__hero').addClass('-animated');

  // Startup animations
  setTimeout(() => {
    setTimeout(() => {
      $('#circle-1').css({
        'fill-opacity': '1',
        'filter': 'unset',
      });
    }, 0);

    setTimeout(() => {
      $('#circle-2').css({
        'fill-opacity': '1',
        'filter': 'unset',
      });
    }, 300);

    setTimeout(() => {
      $('#circle-3').css({
        'fill-opacity': '1',
        'filter': 'unset',
      });
    }, 450);

    setTimeout(() => {
      $('#circle-4').css({
        'fill-opacity': '1',
        'filter': 'unset',
      });
    }, 600);

    setTimeout(() => {
      $('#circle-5').css({
        'fill-opacity': '1',
        'filter': 'unset',
      });
    }, 750);

    setTimeout(() => {
      $('#circle-6').css({
        'fill-opacity': '1',
        'filter': 'unset',
      });
    }, 900);

    setTimeout(() => {
      $('.main-page__hero').addClass('-stop-init-animation');
    }, 2400);
  }, timeOut);

  // Carousel
  if ($('.main-page__accomplishments').length > 0) {
    let $slider = $('.main-page__accomplishments');
    let $items = $slider.find('.main-page__accomplishments__item');
    let $dots = $slider.find('.main-page__accomplishments__navigation__dots__item');
    let $mobile_buttons = $slider.find('.main-page__accomplishments__mobile-buttons').find('.main-page__accomplishments__item__info__button');
    let active = 0;
    let interval = 5000;
    let firstTime = true;

    // Autoplay
    function play() {
      go('next');
    }

    function restartAutoplay() {
      window.clearInterval(window.autoplay);
      window.autoplay = window.setInterval(play, interval);
      window.intervalIds.push(window.autoplay);
    }

    // Go to the slide
    function go(direction) {
      if (!$slider.hasClass('-in-progress')) {
        $slider.addClass('-in-progress');

        active = $slider.find('.main-page__accomplishments__item.-active').index();

        $dots.eq(active).removeClass('-active');
        $items.eq(active).removeClass('-active');
        $mobile_buttons.eq(active).removeClass('-active');

        if (direction === 'next') {// Go Next
          if (active + 1 > $items.length - 1) {
            $dots.eq(0).addClass('-active');
            $items.eq(0).addClass('-active');
            $mobile_buttons.eq(0).addClass('-active');
          } else {
            $dots.eq(active + 1).addClass('-active');
            $items.eq(active + 1).addClass('-active');
            $mobile_buttons.eq(active + 1).addClass('-active');
          }
        } else if (direction === 'previous') {// Go Previous
          if (active - 1 < 0) {
            $dots.eq($items.length - 1).addClass('-active');
            $items.eq($items.length - 1).addClass('-active');
            $mobile_buttons.eq($items.length - 1).addClass('-active');
          } else {
            $dots.eq(active - 1).addClass('-active');
            $items.eq(active - 1).addClass('-active');
            $mobile_buttons.eq(active - 1).addClass('-active');
          }
        }

        $slider.find('.main-page__accomplishments__item').find('video').each(function () {
          $(this).get(0).pause();
        });
        $slider.find('.main-page__accomplishments__item.-active').find('video').get(0).play();

        setTimeout(() => {
          $slider.removeClass('-in-progress');
        }, 250);

        restartAutoplay();
      }
    }

    // Click on dots
    $dots.each(function () {
      $(this).on('click.pxtrigger', function () {
        if (!$(this).hasClass('-active')) {
          $dots.removeClass('-active');
          $(this).addClass('-active');

          $items.removeClass('-active');
          $items.eq($(this).index()).addClass('-active');

          $mobile_buttons.removeClass('-active');
          $mobile_buttons.eq($(this).index()).addClass('-active');

          $slider.find('.main-page__accomplishments__item').find('video').each(function () {
            $(this).get(0).pause();
          });
          $slider.find('.main-page__accomplishments__item.-active').find('video').get(0).play();

          restartAutoplay();
        }
      });
    });

    // Slide left and slide right on tablet and mobile
    if ($(window).width() < 1280) {
      let start = 0;
      let end = 0;

      // Touch start
      document.getElementById('slider').addEventListener('touchstart', function (event) {
        event.stopPropagation();
        start = event.changedTouches[0];
      }, false);

      // Touch end
      document.getElementById('slider').addEventListener('touchend', function (event) {
        event.stopPropagation();

        end = event.changedTouches[0];

        let x = Math.abs(start.pageX - end.pageX);
        let y = Math.abs(start.pageY - end.pageY);

        if (x > 20 || y > 20) {
          // Slide on X axis
          if (x > y) {

            // Slide left (next slide)
            if (end.pageX < start.pageX) {
              go('next');
            }

            // Slide right (previous slide)
            else {
              go('previous');
            }
          }
        }
      }, false);
    }

    // Start to play when the slider will be on the viewport
    $(window).on('scroll.pxtrigger', function () {
      if (
        $(window).scrollTop() >= $slider.offset().top / 3 &&
        $(window).scrollTop() < $slider.offset().top + $slider.innerHeight() / 2
      ) {
        if (firstTime) {
          restartAutoplay();
          firstTime = false;
        }
        $slider.removeClass('-is-paused');
        $slider.find('.main-page__accomplishments__item.-active').find('video').get(0).play();
      } else if ($(window).scrollTop() >= $slider.offset().top + $slider.innerHeight() / 2) {
        $slider.addClass('-is-paused');
        $slider.find('.main-page__accomplishments__item').find('video').each(function () {
          $(this).get(0).pause();
        });
      } else {
        $slider.addClass('-is-paused');
        $slider.find('.main-page__accomplishments__item').find('video').each(function () {
          $(this).get(0).pause();
        });
      }
    });
  }

  if ($('.main-page__video__cover__button').length > 0) {
    let $container = $('.main-page__video');
    let $video = $container.find('video').get(0);
    let $button = $container.find('.main-page__video__cover__button');

    $button.on('click.pxtrigger', function () {
      $container.addClass('-show-video');

      setTimeout(() => {
        $video.play();
      }, 500);
    });
  }
}
