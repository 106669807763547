import $ from 'jquery'
import Cookies from 'js-cookie'

export function checkout () {
  var chargebeeInstance = window.Chargebee.getInstance();

  function redirectTo(url, loaderText){
    $('.loading-container__text').html(loaderText);
    $('#end-waypoint').addClass('-hidden');
    $('.loading-overlay').addClass('-visible');
    setTimeout(function(){
      $('.loading-container').addClass('-visible');
      setTimeout(function(){
        window.location.href = url;
      }, 900);
    }, 400);
  }

  function redirectToDashboard(priceId, experiment, productFamilyId, period, productName, productFamily) {
    let discountCode = '';

    // SA 10-Year Sale
    // if (productFamilyId.toLowerCase() === 'bundle') {
    //   discountCode = '&discount=SA10AAB';
    // }

    experiment = (experiment === 'yes') ? '&experiment=yes' : '';
    productFamilyId = (productFamilyId) ? '&product=' + productFamilyId : '';

    const checkoutURI = '&state=' + encodeURIComponent('/dashboard?a=checkout'  + productFamilyId + experiment + '&price=' + priceId + period + productName + productFamily + discountCode);

    redirectTo(
      window.px_config.pxDashboard.url.auth + checkoutURI,
      'Loading... <span>Please wait...</span>'
    );
  }

  $('body').on('click.pxtrigger', '[data-action="px-checkout"]', e => {
    const element = $(e.target);
    const priceId = element.data('price-id');
    const productFamilyId = element.data('product-family');
    const experiment = element.data('experiment');
    const periodText = element.data('period');
    const productName = element.data('product-name');
    const productFamily = element.data('product-family').toUpperCase();
    e.preventDefault();

    const productIDURI = (productFamilyId) ? '&product=' + productFamilyId : '';
    const experimentURI = (experiment === 'yes') ? '&experiment=yes' : '';
    const periodURI = (periodText) ? '&period=' + encodeURIComponent(periodText) : '';
    const productNameURI = (productName) ? '&productName=' + encodeURIComponent(productName) : '';
    const productFamilyURI = (productFamily) ? '&productFamily=' + encodeURIComponent(productFamily) : '';

    var auth_token = Cookies.get(window.px_config.pxApi.auth.cookie_name);

    // Preventing double clicks
    if(auth_token) {
      $('body').removeClass('cb-loaded'); // Auto-tests
      $('body').addClass('cb-loading'); // Auto-tests

      $.ajax({
        url: window.px_config.pxApi.gql.url,
        data: JSON.stringify({
          query:
            `mutation CreateCheckoutNewSession(
              $priceId: String!,
              $redirectUrl: String!,
              $cancelUrl: String!,
              $quantity: Int
            ) {
              createBillingCheckoutNewSession(
                priceId: $priceId,
                quantity: $quantity,
                redirectUrl: $redirectUrl,
                cancelUrl: $cancelUrl
              ) {
                session
              }
            }`,
          variables: {
            'priceId': priceId,
            'quantity': 1,
            'redirectUrl': window.px_config.pxDashboard.url.thank_you + '&priceId=' + priceId + productIDURI + periodURI + productNameURI + productFamilyURI + experimentURI,
            'cancelUrl': window.px_config.pxDashboard.url.cancel
          }
        }),
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        headers: {
          'apollographql-client-name': window.px_config.pxApi.client.id,
          'apollographql-client-version': window.px_config.pxApi.client.version,
          'Authorization': auth_token
        },

        success: response => {
          if (response.errors) {
            redirectToDashboard(priceId, experiment, productFamilyId, periodURI, productNameURI, productFamilyURI);
            return;
          }

          chargebeeInstance.openCheckout({
            hostedPage: () => {
              return new Promise((resolve, reject) => {
                resolve(response.data.createBillingCheckoutNewSession.session);
              });
            },
            loaded: () => {
              $('.checkout-force-close').addClass('-visible');
              $('body').removeClass('cb-loading'); // Auto-tests
              $('body').addClass('cb-loaded'); // Auto-tests

              // GTM Add to Cart
              const GTMproductsData = {
                id: priceId,
                name: priceId.replace(/-/g, ' '),
                quantity: 1,
                price: parseFloat(element.attr('data-price')),
                currencyCode: element.attr('data-currency-code'),
                period: parseFloat(element.attr('data-gtm-period')),
                variant: 'regular',
                category: element.attr('data-product-family'),
                brand: 'Pixellu',
              };

              let GTMeventName = '';
              if (element.attr('data-product-family') === 'sa') {
                GTMeventName = 'addToCartSA';
              } else if (element.attr('data-product-family') === 'pss') {
                GTMeventName = 'addToCartPSS';
              } else if (element.attr('data-product-family') === 'psf') {
                GTMeventName = 'addToCartPSF';
              } else if (element.attr('data-product-family') === 'bundle') {
                GTMeventName = 'addToCartBundle';
              }

              let actionId = element.attr('data-product-family').toUpperCase() + ' [Add to Cart] ' + priceId.replace(/-/g, ' ');

              if (experiment === 'yes') {
                GTMproductsData.variant = 'experiment';
                actionId = element.attr('data-product-family').toUpperCase() + ' Exp. [Add to Cart] ' + priceId.replace(/-/g, ' ');
              }

              window.dataLayer.push({
                event: GTMeventName,
                ecommerce: {
                  add: {
                    actionField: {
                      id: actionId,
                      step: 1
                    },
                    products: [GTMproductsData],
                  },
                  currencyCode: element.attr('data-currency-code')
                },
              });
            }
          });
        },

        error: response => {
          redirectToDashboard(priceId, experiment, productFamilyId, periodURI, productNameURI, productFamilyURI);
        }
      });
    }
    else{
      redirectToDashboard(priceId, experiment, productFamilyId, periodURI, productNameURI, productFamilyURI);
    }

  });

  $('.checkout-force-close').on('click', () => {
    chargebeeInstance.closeAll();
    $('.checkout-force-close').removeClass('-visible');
    $('body').removeClass('cb-loading'); // Auto-tests
    $('body').removeClass('cb-loaded'); // Auto-tests
  });
}
