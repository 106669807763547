import $ from 'jquery'

/**
 * Reset custom bindings to triggers on global elements
 */
export function resetGlobalBindings() {
  $(window).off('scroll.pxtrigger')
  $(window).off('resize.pxtrigger')

  $(document).off('keyup.pxtrigger')
  $(document).off('click.pxtrigger')
  $(document).off('scroll.pxtrigger')
  $(document).off('keydown.pxtrigger')

  $('body').off('click.pxtrigger')

  $('[data-action="yearly"], [data-action="monthly"], [data-action="switch"]').off('click.pxtrigger');
}
