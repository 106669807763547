import $ from "jquery";
import { langRedirect } from "../helpers/redirect-helper";
import { isBrowser } from "../helpers/general-helper";
import { navigate } from "gatsby"

export function langRedirectService(location) {
  if (!$('html').hasClass('-processed')) {
    const currentLocation = location.pathname.replace(/\/$/, '');
    const browserLang = navigator.language.toLowerCase();

    // Homepage
    if (currentLocation.length === 0 || currentLocation === '' || currentLocation === '/' || $('#px-layout').hasClass('main-page')) {
      langRedirect('/');
      return false;
    }

    // SA pages
    if (
      location.pathname.endsWith('/smartalbums') ||
      location.pathname.endsWith('/smartalbums/')
    ) {
      langRedirect('smartalbums');
      return false;
    }

    else if (
      currentLocation.endsWith('/smartalbums/whatsnew') ||
      currentLocation.endsWith('/smartalbums/whatsnew/')
    ) {
      langRedirect('smartalbums/whatsnew');
      return false;
    }

    else if (
      currentLocation.endsWith('/smartalbums/cloud-proofing') ||
      currentLocation.endsWith('/smartalbums/cloud-proofing/')
    ) {
      langRedirect('smartalbums/cloud-proofing');
      return false;
    }

    else if (
      currentLocation.endsWith('/smartalbums/pricing') ||
      currentLocation.endsWith('/smartalbums/pricing/')
    ) {
      langRedirect('smartalbums/pricing');
      return false;
    }

    else if (
      currentLocation.endsWith('/smartalbums/try') ||
      currentLocation.endsWith('/smartalbums/try/')
    ) {
      langRedirect('smartalbums/try');
      return false;
    }

    else if (
      currentLocation.endsWith('/mobile-trial-download-sign-up/mac') ||
      currentLocation.endsWith('/mobile-trial-download-sign-up/mac/')
    ) {
      langRedirect('mobile-trial-download-sign-up/mac');
      return false;
    }

    else if (
      currentLocation.endsWith('/mobile-trial-download-sign-up/windows') ||
      currentLocation.endsWith('/mobile-trial-download-sign-up/windows/')
    ) {
      langRedirect('mobile-trial-download-sign-up/windows');
      return false;
    }

    else if (
      currentLocation.endsWith('/trial_download_sign_up_follow/mac') ||
      currentLocation.endsWith('/trial_download_sign_up_follow/mac/')
    ) {
      langRedirect('trial_download_sign_up_follow/mac');
      return false;
    }

    else if (
      currentLocation.endsWith('/trial_download_sign_up_follow/windows') ||
      currentLocation.endsWith('/trial_download_sign_up_follow/windows/')
    ) {
      langRedirect('trial_download_sign_up_follow/windows');
      return false;
    }

    else if (
      currentLocation.endsWith('/tutorials-smartalbums') ||
      currentLocation.endsWith('/tutorials-smartalbums/')
    ) {
      langRedirect('tutorials-smartalbums');
      return false;
    }

    // PSF pages
    else if (
      location.pathname.endsWith('/galleries') ||
      location.pathname.endsWith('/galleries/')
    ) {
      langRedirect('galleries');
      return false;
    }

    else if (
      currentLocation.endsWith('/galleries/examples') ||
      currentLocation.endsWith('/galleries/examples/')
    ) {
      langRedirect('galleries/examples');
      return false;
    }

    else if (
      currentLocation.endsWith('/galleries/pricing') ||
      currentLocation.endsWith('/galleries/pricing/')
    ) {
      langRedirect('galleries/pricing');
      return false;
    }

    else if (
      currentLocation.endsWith('/galleries/mobile-galleries-access') ||
      currentLocation.endsWith('/galleries/mobile-galleries-access/')
    ) {
      langRedirect('galleries/mobile-galleries-access');
      return false;
    }

    // PSS pages
    else if (
      currentLocation.endsWith('/smartslides') ||
      currentLocation.endsWith('/smartslides/')
    ) {
      langRedirect('smartslides');
      return false;
    }

    else if (
      currentLocation.endsWith('/smartslides/examples') ||
      currentLocation.endsWith('/smartslides/examples/')
    ) {
      langRedirect('smartslides/examples');
      return false;
    }

    else if (
      currentLocation.endsWith('/smartslides/pricing') ||
      currentLocation.endsWith('/smartslides/pricing/')
    ) {
      langRedirect('smartslides/pricing');
      return false;
    }

    // Other pages
    else if (
      location.pathname.endsWith('/about') ||
      location.pathname.endsWith('/about/')
    ) {
      langRedirect('about');
      return false;
    }

    else if (
      location.pathname.endsWith('/support') ||
      location.pathname.endsWith('/support/')
    ) {
      langRedirect('support');
      return false;
    }

    else if (
      location.pathname.endsWith('/contact') ||
      location.pathname.endsWith('/contact/')
    ) {
      langRedirect('contact');
      return false;
    }

    else if (
      location.pathname.endsWith('/privacy') ||
      location.pathname.endsWith('/privacy/')
    ) {
      langRedirect('privacy');
      return false;
    }

    else if (
      location.pathname.endsWith('/terms') ||
      location.pathname.endsWith('/terms/')
    ) {
      langRedirect('terms');
      return false;
    }

    else if (
      location.pathname.endsWith('/404') ||
      location.pathname.endsWith('/404/')
    ) {
      langRedirect('404');
      return false;
    }

    // Sale page
    // else if ($('#px-layout').hasClass('sale')) {
    //   langRedirect('sale');
    //   return false;
    // }

    // Blog
    else if (location.pathname.includes('/blog')) {
      let redirect_lang = '';
      let redirect_link = '';

      if (
        isBrowser() &&
        browserLang &&
        (browserLang.startsWith('pt') || browserLang.startsWith('es'))
      ) {
        if (browserLang.startsWith('pt')) redirect_lang = 'pt-pt';
        else if (browserLang.startsWith('es')) redirect_lang = 'es-es';
        else redirect_lang = browserLang;

        if (
          currentLocation.endsWith('/blog') ||
          currentLocation.endsWith('/blog/')
        ) {
          redirect_link = '/' + redirect_lang + '/blog';
          navigate(redirect_link);
        } else if (
          currentLocation.endsWith('/blog/education') || currentLocation.endsWith('/blog/education/') ||
          currentLocation.endsWith('/blog/news') || currentLocation.endsWith('/blog/news/') ||
          currentLocation.endsWith('/blog/stories') || currentLocation.endsWith('/blog/stories/')
        ) {
          redirect_link = '/' + redirect_lang + currentLocation.slice(currentLocation.indexOf('/blog'), currentLocation.length);
          navigate(redirect_link);
        }
      }
    }
  }
}
