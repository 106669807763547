import { isBrowser } from "./general-helper";
import { navigate } from "gatsby"

export function langRedirect(path) {
  // Redirect to user's language page if any
  let browserLang = navigator.language;
  let redirect_link = '';

  if (
    isBrowser() &&
    browserLang &&
    (
      browserLang === 'it' ||
      browserLang.startsWith('es') ||
      browserLang.startsWith('pt') ||
      browserLang === 'de' ||
      browserLang === 'fr' ||
      browserLang === 'ru' ||
      browserLang === 'uk'
    )
  ) {
    redirect_link = browserLang;

    if (browserLang === 'it') redirect_link = 'it-it';
    else if (browserLang.startsWith('es')) redirect_link = 'es-es';
    else if (browserLang.startsWith('pt')) redirect_link = 'pt-pt';
    else if (browserLang === 'de') redirect_link = 'de-de';
    else if (browserLang === 'fr') redirect_link = 'fr-fr';

    if (path === '/')
      navigate('/' + redirect_link + '/');
    else
      navigate('/' + redirect_link + '/' + path);
  }
};
